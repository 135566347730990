<template>
  <div>
    <div class="req-temp-container req-details" v-if="paymentRequestDetail">
      <main-details
        :paymentRequestDetail.sync="paymentRequestDetail"
        :calculations="calculations"
        :isAdminLogin.sync="isAdminLogin"
        :markPaymentAsCancelled="markPaymentAsCancelled"
        :resendPaymentRequest="resend"
        :remainingAmount="remainingAmount"
        :productConfigType.sync="productConfigType"
      />
      <transaction @fetchData="fetchDetail" :paymentRequestDetail="paymentRequestDetail" :canRefund="canRefund" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MainDetails from "./MainDetails/MainDetails.vue";
import Transaction from "./Transactions.vue";
import { RRule } from "rrule";

export default {
  components: {
    MainDetails,
    Transaction,
  },

  data() {
    return {
      paymentRequestDetail: {},
      showMore: false,
      calculations: null,
      isAdminLogin: false,
      remainingAmount: 0,
      productFieldsDetail: {},
      partnerId: JSON.parse(localStorage.getItem("user"))._id,
      canRefund: false,
      productConfigType: "",
      paymentSchedules: "",
    };
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  },

  methods: {
    ...mapActions("paymentRequest", ["cancelPaymentRequest", "fetchPaymentRequestDetailById", "resendPaymentRequest"]),
    ...mapActions("payment", ["getQuotationDataForPayLater"]),
    ...mapActions("admin", ["checkLogin"]),

    prepareSchedule() {
      let rule = {
        dtstart: new Date(this.paymentRequestDetail.frequency.startDate),
        freq: this.paymentRequestDetail.frequency.every === "week" ? RRule.WEEKLY : RRule.MONTHLY,
        interval: parseInt(this.paymentRequestDetail.frequency.interval),
      };

      if (this.paymentRequestDetail.frequency.endType === "by") {
        rule.until = this.paymentRequestDetail.frequency.endDate ? this.paymentRequestDetail.frequency.endDate : new Date();
      } else {
        rule.count = this.paymentRequestDetail.frequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestDetail.frequency.endAfterNumberOfPayments) : 1;
      }

      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentSchedules = rule.all();
    },

    async fetchDetail(id) {
      this.$vs.loading();
      await this.fetchPaymentRequestDetailById(id).then((result) => {
        this.paymentRequestDetail = result.data.data;
        this.productConfigType = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, 'payLaterPlan[0].currentPlan.productConfigType', 'THREE_PARTY');
        let pageType = [];

        if ( this.paymentRequestDetail.paymentRequestTemplate && this.paymentRequestDetail.paymentRequestTemplate.length && this.paymentRequestDetail.paymentRequestTemplate[0].requestType === "template") {
          pageType = this.paymentRequestDetail.groupAccessTemplate;
        } else {
          pageType = this.paymentRequestDetail.groupAccessHosted;
        }

        if (pageType && pageType.length > 0) {
          let groupUserAccess = pageType[0];

          if (groupUserAccess.userAccess && groupUserAccess.userAccess.length > 0) {
            let objIndex = groupUserAccess.userAccess.findIndex((obj) => obj.user == this.partnerId);

            if (objIndex >= 0) {
              this.canRefund = groupUserAccess.userAccess[objIndex].canRefund;
            }
          }
        } else {
          this.canRefund = true;
        }

        if (this.paymentRequestDetail.requestOptions.includes("pay-later")) {
          this.getCalculations();
        }

        if (this.paymentRequestDetail.requestType === "recurring") {
          this.prepareSchedule();
        }

        if (!this.paymentRequestDetail.metadata && this.paymentRequestDetail.transactions) {
          const refundedTransactions = this.paymentRequestDetail.transactions.filter((transaction) => transaction.paymentStatus === "Refunded");
          const actualTransaction = this.paymentRequestDetail.transactions.find((transaction) => transaction.paymentStatus === "Completed");

          if (refundedTransactions && refundedTransactions.length) {
            this.refundedAmount = refundedTransactions.map((item) => Math.abs(parseFloat(item.amount))).reduce((prev, next) => prev + next, 0);
            this.remainingAmount = parseFloat(actualTransaction.amount) - this.refundedAmount;
          }
        }
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.response.data.title,
          text: ex.response.data.message,
          color: 'danger',
          iconPack: "feather",
          position: "top-right",
          icon: 'icon-times',
        });

        if (ex.response.status === 404) {
          this.$router.go(-1);
        }
      });

      this.checkIfAdminLogin();
    },
    getCalculations() {
      this.getQuotationDataForPayLater({ paymentRequestId: this.paymentRequestDetail._id }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        this.calculations = {
          total,
          disbursement,
          schedule
        };
      });
    },

    toggleShowMore() {
      this.showMore = !this.showMore;
    },

    checkIfAdminLogin() {
      let loggedUser = this.$store.state.AppActiveUser;

      if (loggedUser.adminId && loggedUser.adminId != "" && loggedUser.loginBy === "flip admin") {
        this.isAdminLogin = true;
      }
    },

    async markPaymentAsCancelled() {
      let data = {
        id: this.$route.params.id,
        data: { flipAdminId: this.user.adminId ? this.user.adminId : null },
      };

      return await this.cancelPaymentRequest(data);
    },

    async resend() {
      let data = {
        id: this.$route.params.id,
        data: { flipAdminId: this.user.adminId ? this.user.adminId : null, sendOnlyComms: this.paymentRequestDetail.paymentStatus == "Pending" },
      };

      return await this.resendPaymentRequest(data).then((response) => {
        if (response.data) {
           this.$vs.notify({
            color: 'success',
            title: 'Payment request',
            text: 'Payment request resent successfully.',
          });
        } else {
          this.$vs.notify({
            color: 'danger',
            title: 'Payment request',
            text: 'We are unable to process your request at this time.',
          });
        }

        this.$router.push({ name: "payment-activity" });
      }).catch((ex) => {
        this.$vs.notify({
          color: 'danger',
          title: 'Payment request',
          text: 'We are unable to process your request at this time.',
        });
        this.$router.push({ name: "payment-activity" });
      });
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },
  },

  mounted() {
    this.fetchDetail(this.$route.params.id);
    this.checkAdminLogin();
  },
};
</script>

