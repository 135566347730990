<template>
  <vs-card>
    <div slot="header">
      <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Request details</h3>
    </div>
    <div class="px-0 pt-0 md:pt-2 md:px-6 lg:px-8">
      <default-display-details
        :paymentRequestDetail.sync="paymentRequestDetail"
        :toggleShowMore="toggleShowMore"
        :cancelPaymentRequest="cancelPaymentRequest"
        :resendPaymentRequest="resendPaymentRequest"
        :calculations="calculations"
        :remainingAmount="remainingAmount"
        :showMore.sync="showMore"
        :productConfigType.sync="productConfigType"
      />
      <div v-if="showMore">
        <ProductCustomFields
          :productFields="paymentRequestDetail.productCustomFields"
          :paymentStatus="paymentRequestDetail.paymentStatus"
          :customPrId="paymentRequestDetail.paymentRequestId"
          v-if="checkProductFieldsDisplay()"
        />
        <custom-fields v-if="checkCustomFieldsDisplay()" :customFields.sync="paymentRequestDetail.customFields" />
        <attached-files v-if="paymentRequestDetail.files.length" :files.sync="paymentRequestDetail.files" />
        <history :paymentRequest.sync="paymentRequestDetail" />
        <contact :paymentRequest.sync="paymentRequestDetail" v-if="productConfigType == 'THREE_PARTY'" />
      </div>
    </div>
  </vs-card>
</template>

<script>
import DefaultDisplayDetails from "./DefaultDisplayDetails.vue";
import CustomFields from "./CustomFields.vue";
import AttachedFiles from "./AttachedFiles.vue";
import Contact from "./Contact.vue";
import History from "./History.vue";
import Legal from "./Legal.vue";
import ProductCustomFields from "./ProductFields.vue";

export default {
  components: {
    DefaultDisplayDetails,
    CustomFields,
    AttachedFiles,
    Contact,
    ProductCustomFields,
    Legal,
    History,
  },

  props: {
    paymentRequestDetail: { type: Object },
    calculations: { type: Object },
    isAdminLogin: { type: Boolean },
    markPaymentAsCancelled: { type: Function },
    resendPaymentRequest: { type: Function },
    remainingAmount: { type: Number },
    productFields: { type: Object },
    productConfigType: { type: String },
  },
  data() {
    return {
      showMore: false,
    };
  },

  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },

    async cancelPaymentRequest() {
      return await this.markPaymentAsCancelled();
    },

    checkProductFieldsDisplay() {
      if (!this.paymentRequestDetail.productCustomFields) {
        return false;
      }

      return this.paymentRequestDetail.productCustomFields.find((customField) => {
        if (customField.editableByMerchant || customField.reqToSendRequest || customField.displayToMerchantAfter || customField.editableByMerchantAfter) {
          return customField;
        };
      });
    },

    checkCustomFieldsDisplay() {
      if (!this.paymentRequestDetail.customFields) {
        return false;
      }
      let check = 0;

      this.paymentRequestDetail.customFields.find((item) => {
        if (item.value) {
          check++;
        }
      });

      return (check > 0);
    },
  },
};
</script>
