<template>
  <vs-row>
    <vs-card class="mt-0" id="transactionWidget">
      <div slot="header">
        <h5 class="font-normal text-lg md:text-xl lg:text-2xl">Transactions</h5>
      </div>
      <div v-if="transactionBind">
        <vs-table v-if="paymentRequestDetail && paymentRequestDetail.transactions.length > 0" :data="paymentRequestDetail.transactions" :max-items="10"  class="stripes">
          <template slot="thead">
            <vs-th> Type </vs-th>
            <vs-th> Date </vs-th>
            <vs-th> Reference </vs-th>
            <vs-th> Amount </vs-th>
            <vs-th> Status </vs-th>
            <vs-th v-if="!paymentRequestDetail.isPaylaterTransaction && paymentRequestDetail.paymentStatus !== 'Refunded'"></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr">
                <div class="flex flex-row text-center">
                  <img v-bind:src="tr.icon" width="30px" class="mr-2"/>
                  {{ tr.typeText }}
                </div>
              </vs-td>
              <vs-td :data="tr.dateFormatted">{{ tr.dateFormatted }}</vs-td>
              <vs-td :data="tr.reference">{{ tr.reference }}</vs-td>
              <vs-td :data="tr.amountFormatted">{{ tr.amountFormatted }}</vs-td>
              <vs-td :data="tr.status"><span :class="getStatusClass(tr.status)">{{ tr.status }}</span></vs-td>
              <vs-td :data="tr.status" v-if="isRefundable(tr)">
                <a @click="refundPopup(tr)" class="underline cursor-pointer">Refund</a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <p v-else>No transactions to show yet.</p>
      </div>
      <div v-else><p>No transactions to show yet.</p></div>
    </vs-card>
    <vs-popup
      v-if="canRefund == true"
      class="refund-popup"
      :active.sync="refundPopupActive"
      @close="closeModal"
      :title="'Refund transaction'"
      :key="refundPopupKey"
      :button-close-hidden="true"
    >
      <vs-row class="bg-gray px-6 md:px-12">
        <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="my-6 md:my-8">
          <vs-radio vs-value="full" class="w-full items-start justify-start" vs-name="refund_type" v-model="refundType">
            <div class="text-base font-light pl-4 md:pl-8 mt-1">Full refund</div>
            <div class="text-dark-blue mt-2 text-xl pl-4 md:pl-8">{{ remainingAmount }}</div>
          </vs-radio>
        </vs-col>
        <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="my-6 md:my-8">
          <vs-radio vs-value="partial" class="w-full items-start justify-start" vs-name="refund_type" v-model="refundType">
            <div class="text-base font-light pl-4 md:pl-8 text-dark-blue mt-1">Partial refund</div>
            <div class="mt-2 pl-4 md:pl-8">
              <money v-model="amount" name="amount" :precision="2" v-bind="moneyMask" class="w-full amount-ph text-dark-blue" :placeholder="'Amount'" :disabled="refundType === 'full'" v-validate="'required||max_value:'+originalAmount"></money>
              <span class="text-danger text-sm" v-show="errors.has('amount')"> {{ errors.first("amount") }} </span>
            </div>
          </vs-radio>
        </vs-col>
      </vs-row>
      <vs-row class="px-6 md:px-12 py-6 md:pt-10 md:pb-12">
        <vs-col vs-w="12" class="">
          <p class="text-base font-light mb-6 md:mb-8">Please enter the below details about the refund transaction:</p>
          <div class="md:mr-12">
            <div class="mb-6 md:mb-8 sm:w-5/12">
              <label for="reference" class="w-full text-base font-light text-dark-blue block pb-1">Transaction reference <span class="mid-blue">*</span></label>
              <vs-input class="w-full text-dark-blue" placeholder="" v-model="reference" v-validate="'required'" name="reference" maxLength="20"/>
              <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first("reference") }}</span>
            </div>
          </div>

          <div class="md:mr-12">
            <div class="mb-6 md:mb-8 sm:w-5/12">
              <label for="name" class="w-full text-base font-light text-gray block pb-1">Customer name</label>
              <vs-input class="w-full text-dark-blue" placeholder="Customer name" v-model="name" name="name" maxLength="20"/>
              <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
            </div>
          </div>

          <div class="sm:flex sm:justify-between md:mr-12">
            <div class="sm:w-5/12 mb-6 md:mb-8">
              <label for="email" class="w-full text-base font-light text-gray block pb-1">Customer email</label>
              <vs-input class="w-full text-dark-blue" placeholder="Customer email" v-model="email" v-validate="'email'" name="email"/>
              <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>
            </div>

            <div class="sm:w-5/12 mb-6 md:mb-8">
              <label class="w-full text-base font-light text-dark-blue block pb-1">Customer mobile phone </label>
              <the-mask :class="maskedMobileNumber ? 'hasValue' : ''" :mask="['#### ### ###']" v-model="maskedMobileNumber" masked name="mobile" class="text-dark-blue" v-validate="{ regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,}" placeholder=""/>
              <span class="text-danger text-sm" v-show="errors.has('mobile')">{{ errors.first("mobile") }}</span>
            </div>

          </div>
        </vs-col>

        <vs-col>
          <vs-col vs-w="12" class="text-right pt-6">
            <vs-button color="primary" v-round size="large" @click="refundTransaction" class="mr-8">Refund</vs-button>
            <a class="text-button text-sm" @click="closeModal">Cancel</a>
          </vs-col>
        </vs-col>

      </vs-row>
    </vs-popup>
  </vs-row>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import { RRule } from "rrule";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      mobile: "mobile phone",
    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
      max_value: () =>
        "You cannot refund more than original transaction amount",
    },
  },
};
Validator.localize(dictionary);

export default {
  name: "Transaction",
  props: ["paymentRequestDetail", "canRefund"],
  components: {
    TheMask,
    Money,
  },
  data() {
    return {
      moneyMask: {
        decimal: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      refundedAmount: 0,
      refundableAmount: 0,
      remainingAmount: 0,
      showPayLater: process.env.VUE_APP_SHOW_PAY_LATER
        ? process.env.VUE_APP_SHOW_PAY_LATER
        : true,
      refundPopupActive: false,
      activeTransaction: {},
      refundType: "full",
      amount: 0,
      reference: "",
      name: "",
      email: "",
      mobile: "",
      maskedMobileNumber: "",
      confirmation: false,
      refundPopupKey: 0,
      transactionBind: false,
      originalAmount : 0,
    };
  },
  mounted() {
    this.checkRequestType();
  },
  watch: {
    paymentRequestDetail(val) {
      if (val) {
        this.checkRequestType();
        var el = document.getElementById("transactionWidget");
        this.$scrollTo(el);
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("transaction", ["refundPaymentRequestTransaction"]),
    checkRequestType() {
      if (this.paymentRequestDetail.requestType === "recurring"){
        this.prepareSchedule();
      }
      if (!this.paymentRequestDetail.metadata && this.paymentRequestDetail.transactions) {
        this.originalAmount = this.paymentRequestDetail.paymentSummary.rawAmount;

        let refundedTransactions = this.paymentRequestDetail.transactions.filter((transaction) => { return transaction.status === "Refunded"; });
        let actualTransaction = this.paymentRequestDetail.transactions.find((transaction) => { return transaction.status === "Completed";});

        if (refundedTransactions.length) {
          this.refundedAmount = refundedTransactions.map((item) => Math.abs(parseFloat(item.amount))).reduce((prev, next) => prev + next, 0);
          this.remainingAmount = parseFloat(actualTransaction.amount) - this.refundedAmount;
        } else {
          this.remainingAmount = actualTransaction ? actualTransaction.amount : 0
        }
      }
      this.transactionBind = true;
    },

    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },

    prepareSchedule() {
      let rule = {
        dtstart: new Date(this.paymentRequestDetail.frequency.startDate),
        freq: this.paymentRequestDetail.frequency.every === "week" ? RRule.WEEKLY : RRule.MONTHLY,
        interval: parseInt(this.paymentRequestDetail.frequency.interval),
      };

      if (this.paymentRequestDetail.frequency.endType === "by") {
        rule.until = this.paymentRequestDetail.frequency.endDate ? this.paymentRequestDetail.frequency.endDate : new Date();
      } else {
        rule.count = this.paymentRequestDetail.frequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestDetail.frequency.endAfterNumberOfPayments) : 1;
      }
    },

    refundPopup(transaction) {
      this.amount = this.remainingAmount;
      this.refundableAmount = this.amount
      this.refundPopupActive = true;
      this.activeTransaction = transaction;
      this.name = this.paymentRequestDetail.payeeName;
      this.email = this.paymentRequestDetail.payeeEmail;
      this.maskedMobileNumber = this.paymentRequestDetail.payeePhone;
    },

    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    refundTransaction() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let phoneNumber = this.maskedMobileNumber;
          this.mobile = phoneNumber.split(" ").join("");

          let data = {
            refundType: this.refundType,
            amount: this.amount,
            reference: this.reference,
            name: this.name,
            email: this.email,
            mobile: this.mobile,
            transactionId: this.activeTransaction._id,
            paymentRequestId: this.activeTransaction.paymentRequestId,
          };
          if (this.user && this.user.adminId && this.user.loginBy) {
            data.refundedByAdmin = this.user.adminId;
          }
          if (this.canRefund) {
            this.$vs.loading();
            await this.refundPaymentRequestTransaction(data)
              .then((result) => {
                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "Transaction has been refunded successfully.",
                  "success",
                  "icon-check-circle"
                );
                this.closeModal();
                this.$emit("fetchData", this.$route.params.id);
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.showMessage(
                  "Failed",
                  "Transaction could not be refunded",
                  "danger",
                  "icon-close-circle"
                );
                this.closeModal();
              });
          } else {
            this.showMessage(
              "Failed",
              "Permission denied",
              "danger",
              "icon-close-circle"
            );
          }
        }
      });
    },

    closeModal() {
      this.refundPopupActive = false;
      this.activeTransaction = {};
      this.refundType = "full";
      this.amount = 0;
      this.reference = "";
      this.name = "";
      this.email = "";
      this.mobile = "";
      this.maskedMobileNumber = "";
      this.confirmation = false;
      this.refundPopupKey += 1;
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    getStatusClass(status) {
      let label = "";

      if (["Successful", "Completed"].includes(status)) {
        label = "label label-success";
      } else if (["Pending", "Queued", "Awaiting Approval", "Draft"].includes(status)) {
        label = "label label-warning";
      } else if (["Failed", "Cancelled"].includes(status)) {
        label = "label label-danger";
      } else {
        label = "label label-primary";
      }

      return label;
    },

    isRefundable(tr) {
      const checkType = (this.paymentRequestDetail.requestType === "one-off" || !this.paymentRequestDetail.requestType);
      const rowStatus = !["Failed", "Refunded", "Rejected"].includes(tr.paymentStatus);
      const prStatus = this.paymentRequestDetail.paymentStatus !== "Refunded";
      const isPaylater = !this.paymentRequestDetail.isPaylaterTransaction;
      const isManual = !this.paymentRequestDetail.manualPaymentByAdmin;
      const result = (this.canRefund && checkType && rowStatus && prStatus && isPaylater);

      return result;
    }
  },
};
</script>