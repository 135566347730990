<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Product fields</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10 pr-detail-fields">
      <div v-for="(customField, key) in localData" :key="key" class="w-full">
        <div class="custom-fields w-full" v-if="(customField.editableByMerchant || customField.reqToSendRequest || customField.displayToMerchantAfter || customField.editableByMerchantAfter)">
          <h3 class="font-normal text-base flex justify-between">
            <span>{{ customField.label }}</span>
            <span class="lg:mr-20 md:mr-16 sm:mr-8 mr-4">
              <img
                v-if="customField.editableByMerchantAfter && ['Active', 'Extended', 'Submitted'].includes(paymentStatus)"
                @click="openEditMode(key, customField.value, customField.type)"
                :src="editIcon"
                class="cursor-pointer"
              />
            </span>
          </h3>
          <p class="mt-2" v-if="customField.type !== 'address'">
            <S3FileGenerator v-if="customField.type === 'file'" :document="customField.value" :key="customField.value"/>
            <span v-else>
              <p class="mt-2" v-if="customField.type==='currency'"> $ {{customField.value}} </p>
              <p class="mt-2" v-else-if="customField.type==='number'"> {{ customField.value }} </p>
              <p class="mt-2" v-else-if="customField.type==='date'"> {{ customField.value ? formattedDate(customField.value) : customField.value }} </p>
              <p class="mt-2" v-else> {{customField.value}}</p>
            </span>
          </p>
          <div v-if="customField.type === 'address'" class="mb-4">
            <p class="mt-2" v-html="formatAddress(customField.value)"></p>
          </div>
        </div>
        <div v-if="customField.isOpen" class="custom-fields w-full">
          <date-picker
            v-if="customField.type === 'date'"
            valueType="format"
            v-model="customField.value"
            lang="en"
            type="date"
            placeholder=""
            class="w-auto"
            format="YYYY-MM-DD"
          ></date-picker>

          <template v-else-if="customField.type === 'currency'">
            <money
              v-validate="{
                required: customField.reqToSendRequest ? true : false,
              }"
              :name="`${customField.label}`"
              v-model="customField.value"
              class="w-full"
              v-bind="moneyMask"
            />
            <span class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">{{ errors.first(`${customField.label}`) }}</span>
          </template>

          <template v-else-if="customField.type === 'file'">
            <input
              :ref="`uploadImgInput${key}`"
              type="file"
              class="hidden"
              :name="customField.label"
              v-validate="{ required : true, size: maxFileSize * 1024 }"
              @change="updateCurrImg($event, key)"
              accept=".pdf"
            />
            <div class="text-danger text-sm pt-1" v-show="errors.has(`${customField.label}`)">
              {{ errors.first(`${customField.label}`) }}
            </div>
            <vs-button
              flat
              class="only-border-btn small"
              small
              v-round
              @click="uploadImgInput(key)"
            >
              Upload
            </vs-button>
            <span v-if="customField.file">
              {{ customField.file.name }}
            </span>
          </template>

          <vs-select v-else-if="customField.type === 'dropdown'" class="selectExample" v-model="customField.value">
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in customField.dropdownSelectFields"
            />
          </vs-select>

          <template v-else-if="customField.type === 'address'">
            <label class="text-base">
              {{ customField.label }}
              <span v-if="customField.reqToSendRequest" class="mid-blue">*</span>
            </label>
            <vs-row class="my-5">
              <vs-col vs-w="7">
                <vue-google-autocomplete
                  ref="address"
                  :id="customField._id"
                  :value="productAddressFieldArray[customField._id].rawAddress"
                  classname="w-full custom-w"
                  v-on:placechanged="getAddressForProductField"
                  :country="['au']"
                  placeholder="Start typing your address"
                >
                </vue-google-autocomplete>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5">
              <vs-col vs-w="12" class="custom-mt pr-xs-0 lg:mt-0 sm:mt-5">
                <label class="text-base font-light w-full">Apt, Suite, Unit, Building, Floor</label>
                <vs-input class="w-full custom-w" v-model="productAddressFieldArray[customField._id].unit_number" name="unit_number" />
                <span class="text-danger text-sm" v-show="errors.has('unit_number')">{{ errors.first("unit_number") }}</span>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5">
              <div>
                <label class="text-base font-light">Suburb</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('suburb')">{{ errors.first("suburb") }}</span>
                  <vs-input class="w-full custom-w" v-model="productAddressFieldArray[customField._id].suburb" name="suburb" v-validate="'required'" />
                </div>
              </div>
            </vs-row>

            <vs-row class="mb-5">
              <vs-col vs-w="12" class="custom-mt mt-5">
                <label class="text-base font-light w-auto">Street address</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('street_name')">
                    {{ errors.first("street_name") }}
                  </span>
                  <vs-input class="w-full custom-w add-street" v-model="productAddressFieldArray[customField._id].street_name" name="street_name" v-validate="'required'" />
                </div>
              </vs-col>
            </vs-row>

            <vs-row class="mb-5">
              <div class="custom-mt-sm pr-5 pr-xs-0">
                <label class="w-full text-base font-light">State</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('state')">
                    {{ errors.first("state") }}
                  </span>
                  <vs-select
                    autocomplete
                    v-model="productAddressFieldArray[customField._id].state"
                    class="w-full custom-w"
                    name="state"
                    v-validate="'required'"
                    laceholder="Select state"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in states"
                    ></vs-select-item>
                  </vs-select>
                </div>
              </div>
            </vs-row>

            <vs-row class="mb-5">
              <div class="custom-mt-sm">
                <label class="w-full text-base font-light">Postcode</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('postcode')">{{ errors.first("postcode") }}</span>
                  <the-mask
                    class="w-full custom-w vs-input--input"
                    type="text"
                    masked
                    :mask="['####']"
                    v-model="productAddressFieldArray[customField._id].postcode"
                    name="postcode"
                    v-validate="'required'"
                  />
                </div>
              </div>
            </vs-row>
          </template>

          <vs-input v-else v-model="customField.value"></vs-input>

          <vs-row class="flex mt-2">
            <vs-button
              class="primary flex items-center justify-center lg:mr-8 mr-4"
              size="small"
              v-validate="'required'"
              @click="saveProductField(key)"
              v-round
              >Save</vs-button
            >
            <div class="mid-blue cursor-pointer" @click="cancelEditMode(key)">
              <u>Cancel</u>
            </div>
          </vs-row>
        </div>
        <div class="custom-fields w-full" v-if="((customField.displayToMerchantAfter === true && customField.value) || customField.editableByMerchantAfter === true)">
          <a @click="openAuditLogPopUp(customField)" v-if="customField.auditLogs">
            <span v-for="(logDetail, key) in customField.auditLogs" :key="key">
              <span v-if="key == 0">Last updated on {{ getLastUpdatedDate(customField.auditLogs) }}</span>
            </span>
          </a>
        </div>
      </div>
    </div>
    <vs-popup class="holamundo" title="Product field name audit log" :active.sync="auditLogPopup">
      <div v-for="(auditLog, key) in activeAuditLog" :key="key">
        <h3>Updated by {{ auditLog.updateBy }}</h3>
        <div>
          Image
          <p>{{ auditLog.userFullname }}</p>
          <p>
            {{ moment(auditLog.createdDate) }} at
            {{ getTime(auditLog.createdDate) }}
          </p>
        </div>
        <p>Old Value</p>
        <h5>{{ auditLog.oldValue }}</h5>
        <p>New Value</p>
        <h5>{{ auditLog.newValue }}</h5>
      </div>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="close" class="mr-5">Close</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import { TheMask } from "vue-the-mask";
import { Money } from "v-money";
import S3FileGenerator from "@/views/components/S3FileGenerator";
import _ from "lodash";

export default {
  name: "ProductFields",
  components: {
    S3FileGenerator,
    DatePicker,
    TheMask,
    VueGoogleAutocomplete,
    Money,
  },
  props: {
    productFields: { type: Array },
    paymentStatus: { type: String },
    customPrId: { type: String },
  },
  data() {
    return {
      auditLogPopup: false,
      activeAuditLog: [],
      editIcon: require('@/assets/images/edit.png'),
      localData: [],
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
      prId: "",
      productAddressFieldArray: {},
      productAddressField: {
        rawAddress: "",
        index: "",
        suburb: "",
        state: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
      },
      baseUrl: process.env.VUE_APP_API_URL,
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["saveProductFieldInPr"]),
    openEditMode(key, value, type) {
      this.localData[key].isOpen = !this.localData[key].isOpen;
      this.localData[key].oldValue = value;
    },

    cleanAddress(val) {
      let productAddressField = JSON.parse(val);

      // [StreetNo] [StreetName}, [Suburb], [State] [Postcode], [Country]
      let address ="";
      if (productAddressField.street_number) {
        address = `${productAddressField.street_number} `
      }

      if (productAddressField.street_name) {
        address = `${productAddressField.street_name},`
      }

      if (productAddressField.suburb) {
        address+=` ${productAddressField.suburb}`
      }

      if (productAddressField.state) {
        address+=`, ${productAddressField.state}`
      }

      if (productAddressField.postcode) {
        address+=`, ${productAddressField.postcode}`
      }

      if (productAddressField.country) {
        address+=`, ${productAddressField.country}`
      }

      return address
    },
    formatAddress(val){
      if(!val) return val;
      let obj = JSON.parse(val);
      let address = "";
      if (obj.unit_number && Object.keys(obj.unit_number).length > 0) {
        address = obj.unit_number + "<br>";
      }

      if (obj.street_name && Object.keys(obj.street_name).length > 0) {
        address = address + obj.street_name + "<br>";
      } else if(obj.street && Object.keys(obj.street).length > 0) {
        address = address + obj.street + "<br>";
      }

      if (obj.suburb && Object.keys(obj.suburb).length > 0) {
        address = address + obj.suburb + " ";
      }

      if (obj.state && Object.keys(obj.state).length > 0) {
        address = address + obj.state;
        if (Object.keys(obj.postcode).length > 0) {
          address = address + " " + obj.postcode + "<br>";
        } else {
          address = address + "<br>";
        }
      }

      if (obj.country && Object.keys(obj.country).length > 0) {
        address = address + obj.country;
      } else {
        address = address + "Australia";
      }

      return address;
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    formattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    getAddressForProductField: function (addressData, placeResultData, _id) {
      this.addressData = addressData;

      if (this.addressData.street_number) {
        this.productAddressFieldArray[_id].street_number =
          this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.productAddressFieldArray[_id].postcode =
          this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.productAddressFieldArray[_id].suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.productAddressFieldArray[_id].state =
          this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";
        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
          }
        }
        this.productAddressFieldArray[_id].street_name = street_address;
      }
    },
    async saveProductField(key) {
      const saveData = this.localData[key];

      if (saveData.type === "address") {
        saveData.value = JSON.stringify(this.productAddressFieldArray[saveData._id]);
      }

      saveData.paymentRequestId = this.prId;
      saveData.customPrId = this.customPrId;
      const fd = new FormData();
      fd.append("file", saveData.file);
      fd.append("form", JSON.stringify(saveData));

      const obj = {
        data: fd,
        config: {
          header: {
            "Content-Type": "multipart/form-data",
          },
        },
      };
      this.$vs.loading();

      await this.saveProductFieldInPr(obj).then((response) => {
        this.$vs.loading.close();
        const resultData = response.data.data;
        this.localData = resultData.map((item) => ({
          ...item,
          isOpen: false,
          oldValue: "",
        }));

        this.localData.forEach((item) => {
          if (item.type == "currency") {
            item.value = this.addZeroes(item.value);
          }
        });

      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },
    cancelEditMode(key) {
      this.localData[key].isOpen = false;
    },
    getLastUpdatedDate(auditLog) {
      return moment(auditLog[auditLog.length -1].createdDate).format("D MMMM YYYY");
    },
    moment(date) {
      return moment(date).format("D MMMM YYYY");
    },
    getTime(date) {
      return moment(date).format("HH:mm");
    },
    uploadImgInput(key) {
      this.$refs[`uploadImgInput${key}`][0].click();
    },
    updateCurrImg(input, key) {
      this.localData[key].file = input.target.files[0];
    },
    openAuditLogPopUp(clickedProductField) {
      this.auditLogPopup = true;
      this.activeAuditLog = clickedProductField.auditLogs;
    },
    close() {
      this.auditLogPopup = false;
      this.activeAuditLog = [];
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },
  created() {
    this.localData = this.productFields.map((item) => ({
      ...item,
      isOpen: false,
      oldValue: "",
      file: null,
    }));

    this.prId = this.$route.params.id;

    for (let i = 0; i < this.localData.length; i++) {
      if (this.localData[i].type == "currency") {
        this.localData[i].value =this.localData[i].value.replace("$ ", "");
        this.localData[i].value = this.addZeroes(this.localData[i].value);
      }

      if (this.localData[i].type === "address") {
        this.productAddressField = JSON.parse(this.localData[i].value);
        this.productAddressField.rawAddress = `${this.productAddressField.street_name},${this.productAddressField.suburb} ${this.productAddressField.state}, Australia`;
        this.productAddressFieldArray[this.localData[i]._id] = this.productAddressField;
      }
    }
  },
};
</script>

