<template>
  <div class="">
    <h3 class=" text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Contact details</h3>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10"
    >
      <div>
        <h3 class="font-light text-base">Customer</h3>
        <p class="mt-2" v-if="paymentRequest.payeeName">{{ paymentRequest.payeeName }}</p>
        <p class="mt-1" v-if="paymentRequest.payeePhone">{{ paymentRequest.payeePhone }}</p>
        <a class="text-base"
          v-if="paymentRequest.payeeEmail"
          :href="`mailto:${paymentRequest.payeeEmail}`"
          ><u class="text-base">{{ paymentRequest.payeeEmail }}</u></a
        >
        <p
          v-if="
            !paymentRequest.payeeName &&
              !paymentRequest.payeePhone &&
              !paymentRequest.payeeEmail
          "
        >
          Customer details not available
        </p>
      </div>
      <div >
        <h3 class="font-light text-base">Merchant</h3>
        <div v-if="paymentRequest.senderDetails">
          <p class="mt-2" v-if="paymentRequest.senderDetails.fullname">
            {{ paymentRequest.senderDetails.fullname }}
          </p>
          <p class="mt-1" v-if="paymentRequest.senderDetails.senderPhone">
            {{ paymentRequest.senderDetails.senderPhone }}
          </p>
          <a  class="text-base"
            v-if="paymentRequest.senderDetails.senderEmail"
            :href="`mailto:${paymentRequest.senderDetails.senderEmail}`"
            ><u>{{ paymentRequest.senderDetails.senderEmail }}</u></a
          >
        </div>
      </div>
      <!-- <div>
        <h3 class="font-normal text-base">Support contact</h3>
        <div v-if="paymentRequest.globalSupportDetails">
          <p class="mt-2">{{ paymentRequest.globalSupportDetails.fullname }}</p>
          <p>{{ paymentRequest.globalSupportDetails.senderPhone }}</p>
          <a class="text-base" :href="`mailto:${paymentRequest.globalSupportDetails.senderEmail}`"
            ><u>{{ paymentRequest.globalSupportDetails.senderEmail }}</u></a
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paymentRequest: { type: Object }
  }
};
</script>