<template>
  <div>
    <vs-row class="flex justify-between">
      <!-- left side -->
      <div class="w-full lg:w-1/2">
        <!-- Displays name/email/phone accordingly to whichable is available first, if non of these exists, Customer details not available is displayed -->
        <div class="flex flex-wrap items-center justify-between wrap-span">
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeeName">{{ paymentRequestDetail.payeeName }}</h3>
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeeEmail && !paymentRequestDetail.payeeName">
            <a :href="`mailto:${paymentRequestDetail.payeeEmail}`">{{paymentRequestDetail.payeeEmail}}</a>
          </h3>
          <h3 class="font-normal mid-blue mb-2" v-if="paymentRequestDetail.payeePhone && !paymentRequestDetail.payeeName && !paymentRequestDetail.payeeEmail">{{ paymentRequestDetail.payeePhone }}</h3>
          <h3 class="font-normal mb-2" v-if=" !paymentRequestDetail.payeePhone && !paymentRequestDetail.payeeName && !paymentRequestDetail.payeeEmail && productConfigType == 'THREE_PARTY'">Customer details not available</h3>
        </div>
        <!--  -->
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Request ID:</p>
          <a class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base" v-if="['Pending', 'Re-sent'].includes(paymentRequestDetail.paymentStatus)" @click="gotoPaymenPage">
            {{ paymentRequestDetail.paymentRequestId }}
          </a>
          <span v-else class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.paymentRequestId }}</span>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <p class="w-24 m-0 mr-4 text-base">Reference:</p>
          <span class="m-0 lg:w-1/2 md:w-auto sm:w-auto text-base">{{ paymentRequestDetail.reference }}</span>
        </div>
        <div class="flex flex-wrap items-center wrap-span mb-2">
          <!-- for one off types of payments -->
          <p class="m-0 mr-4 text-base" v-if="isOneOff">{{ paymentRequestType(paymentRequestDetail) }}</p>
          <!-- for recurring type of payments -->
          <p class="w-24 m-0 text-base" v-else-if="isRecurring">Recurring</p>
          <!-- for one off, pay later, pending state, not clickable, non popup-->
          <span
            v-if="
              selectedPlan &&
              ((selectedPlan.planText && selectedPlan.planText.length > 0 ) || selectedPlan.productText) &&
              isOneOff &&
              hasPaylater &&
              ['Pending', 'Re-sent'].includes(paymentRequestDetail.paymentStatus)
            "
            class="lg:w-1/2 md:w-auto sm:w-auto m-0"
            >{{ selectedPlan.productText ||selectedPlan.planText[0] }}</span
          >
          <!-- for one off, pay later, every other state beside active, clickable, popups plans -->
          <span
            v-else-if="
              selectedPlan &&
              ((selectedPlan.planText && selectedPlan.planText.length > 0) || selectedPlan.productText) &&
              isOneOff &&
              hasPaylater &&
              ['Active', 'Cancelled', 'Completed', 'Extended', 'Expired', 'Submitted'].includes(paymentRequestDetail.paymentStatus)
            "
            class="lg:w-1/2 md:w-auto sm:w-auto"
            @click="showPlans"
          >
            <u class="pointer link text-base">{{ selectedPlan.productText ||selectedPlan.planText[0] }}</u>
          </span>
          <!-- for recurring, active state, clickable, popups billing stuff-->
          <span v-else-if="isRecurring && paymentRequestDetail.paymentStatus === 'Active'" class="lg:w-1/2 md:w-auto sm:w-auto mb-0 ml-4 text-base">
            <u class="link">billed {{ paymentRequestDetail.frequency.interval }} times every {{ paymentRequestDetail.frequency.every }}</u>
          </span>

          <!-- plans pop up -->
          <payment-plans-pop-up
            v-if="payLaterPopUp"
            title="Payment schedule"
            :isOpen.sync="payLaterPopUp"
            :calculations="calculations"
            :paymentRequest="paymentRequestDetail"
            :onClose="closePayLaterPopUp"
          />
        </div>
        <div class="mt-4">
          <span :class="`payment-status mr-5 font-normal inline-block ${conditionalClass(paymentRequestDetail.paymentStatus)}`">{{ paymentRequestDetail.paymentStatus }}</span>
          <span class="text-base block md:inline-block pt-2 md:pt-0">{{ ["Pending", "Re-sent"].includes(paymentRequestDetail.paymentStatus) ? `(expires ${momentExpiryDate(paymentRequestDetail.expiredDate)})` : "" }}</span>
        </div>
        <br />
        <br />
        <div class="button-combo">
          <vs-button
            flat
            @click="openDeletePopUp"
            v-round
            size="medium"
            class="only-border-btn"
            v-if="['Pending', 'Re-sent', 'Info-required'].includes(paymentRequestDetail.paymentStatus)  || (paymentRequestDetail.paymentStatus === 'Expired' &&
              isOneOff && hasPayNow) ||
              (paymentRequestDetail.paymentStatus === 'Active' && isRecurring)
            "
            :disabled="paymentRequestDetail.paymentStatus === 'Expired'"
            >
            Cancel Request
          </vs-button>
          <vs-button flat @click="openResendPopUp" v-round size="medium" class="ml-3" v-if="['Expired', 'Pending'].includes(paymentRequestDetail.paymentStatus)">
            <span>Resend Request</span>
          </vs-button>
        </div>
      </div>

      <!-- right side -->
      <div class="w-full lg:w-1/2 flex grid flex-col">
        <div class="lg:w-4/5 w-full justify-self-end">
          <div class="flex flex-col wrap-span" v-if="isOneOff && (hasPayNow || hasPaylater)">
            <div class="flex flex-wrap items-center justify-between wrap-span mb-2 lg:mt-0 mt-10">
              <h3 class="lg:text-xl md:text-xl text-lg text-black m-0 font-normal">Request amount</h3>
              <h3 class="font-normal mid-blue">
                {{ paymentRequestDetail.paymentSummary.requestAmount }}
              </h3>
            </div>
            <div class="flex mb-2"
              v-if="isOneOff && hasPayNow &&
                paymentRequestDetail.paymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))              "
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Fees</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentSummary.fees }}
              </h3>
            </div>

            <div class="flex mb-2"
              v-if="isOneOff && hasPayNow &&
                paymentRequestDetail.paymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))
              "
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Amount payable by customer</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                <template v-if="paymentRequestDetail.payNowUsedCard">{{ paymentRequestDetail.paymentSummary.payableByCustomer }}</template>
              </h3>
            </div>

            <div class="flex mb-2"
              v-if="isOneOff && hasPayNow &&
                paymentRequestDetail.paymentStatus === 'Completed' && !paymentRequestDetail.manualPaymentByAdmin && (!paymentRequestDetail.selectedPaymentPlan || (paymentRequestDetail.selectedPaymentPlan && Object.keys(paymentRequestDetail.selectedPaymentPlan).length == 0))
              "
              >
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Payable to you</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                <template v-if="paymentRequestDetail.payNowUsedCard">
                  {{ paymentRequestDetail.paymentSummary.payableToYou }}
                </template>
              </h3>
            </div>
          </div>

          <div class="flex flex-col wrap-span" v-else-if="isRecurring">
            <div v-if="showingFeesStatus.includes(paymentRequestDetail.paymentStatus)" class="font-normal text-black text-2xl">Payment Detail</div>
            <div v-if="showingFeesStatus.includes(paymentRequestDetail.paymentStatus)" class="flex">
              <p class="mb-0 lg:w-2/3 md:w-auto w-max lg:text-lg md:text-base text-sm">Recurring amount</p>
              <h3 class="font-normal lg:w-1/3 md:w-auto w:max text-right mid-blue">{{ moneyFormat(paymentRequestDetail.amount.$numberDecimal) }}</h3>
            </div>

            <div class="flex mb-2" v-if="paymentRequestDetail.paymentStatus === 'Active'">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Fees</p>
              <h3 class="lg:w-1/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentStatus === "Active" ? moneyFormat(paymentRequestDetail.transactions[0].surcharge) : 0}}
              </h3>
            </div>

            <div class="flex mb-2" v-if="paymentRequestDetail.paymentStatus === 'Active'">
              <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Total recurring amount</p>
              <h3 class="lg:w-1/4 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey text-right">
                {{ paymentRequestDetail.paymentStatus === "Active" ? moneyFormat(paymentRequestDetail.transactions[0].totalAmount) : moneyFormat(paymentRequestDetail.amount.$numberDecimal) }}
              </h3>
            </div>
          </div>
          <!-- Pay later -->
          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if=" isOneOff && hasPaylater && showFeeDiv() && showingFeesStatus.includes(paymentRequestDetail.paymentStatus)">
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">
              Service fee ({{ selectedPlan && selectedPlan.feeModel=='MSF_INCLUDED'? "paid by merchant" : "paid by customer" }})
            </p>
            <span class="font-normal lg:w-1/ md:w-auto w-max text-right text-base">
              {{ paymentRequestDetail.paymentSummary.fees }}
            </span>
          </div>

          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv() && showingFeesStatus.includes(paymentRequestDetail.paymentStatus)">
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Total payable by customer</p>
            <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
              {{ paymentRequestDetail.paymentSummary.payableByCustomer }}
            </span>
          </div>

          <div class="flex flex-wrap items-center justify-between wrap-span mb-2" v-if="isOneOff && hasPaylater && showFeeDiv() && showingFeesStatus.includes(paymentRequestDetail.paymentStatus)">
            <p class="lg:w-2/3 md:w-auto w-max lg:text-base md:text-base text-sm font-normal color-grey">Payable to you</p>
            <span class="font-normal lg:w-1/3 md:w-auto w-max text-right text-base">
              {{ paymentRequestDetail.paymentSummary.payableToYou }}
            </span>
          </div>
        </div>
      </div>
    </vs-row>
    <vs-row class="flex justify-center pointer pt-5">
      <div @click="toggleShowMore" class="see-more flex flex-col">
        <p class="text-base">See more</p>
        <vs-icon :icon="showMore ? 'expand_less' : 'expand_more'" size="small"></vs-icon>
      </div>
    </vs-row>

    <!-- pop up after cancel request is clicked -->
    <vs-popup class="holamundo" title="Resend Request" :active.sync="resendPopUp">
      <p>Are you sure you want to resend this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="resend" class="mr-5" :disabled="resendButtonDisable">{{ resendButtonText }}</vs-button>
        <vs-button @click="hideResendPopup" :disabled="resendButtonDisable">Cancel</vs-button>
      </div>
    </vs-popup>

    <!-- pop up after cancel request is clicked -->
    <vs-popup class="holamundo" title="Cancel Request" :active.sync="deletePopUp">
      <p>Are you sure you want to cancel this payment request?</p>
      <div class="flex-end mt-5 flex-end">
        <vs-button @click="cancel" class="mr-5">Confirm</vs-button>
        <vs-button @click="hideDeletePopup">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moment from "moment";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
import VeeValidate from "vee-validate";
import PaymentPlansPopUp from "./PaymentPlansPopUp.vue";

export default {
  components: {
    PaymentPlansPopUp,
    Money,
    DatePicker,
  },

  props: {
    paymentRequestDetail: { type: Object },
    toggleShowMore: { type: Function },
    cancelPaymentRequest: { type: Function },
    resendPaymentRequest: { type: Function },
    calculations: { type: Object },
    showMore: { type: Boolean },
    productConfigType: { type: String },
  },

  data() {
    return {
      payLaterPopUp: false,
      deletePopUp: false,
      msf: 0,
      totalAmount: 0,
      resendPopUp: false,
      registerBankTransferPopup: false,
      amPm: "",
      counterDanger: "",
      register: {
        amount: "",
        paymentDate: "",
        paymentTime: "",
        transactionReference: "",
        auditNote: "",
      },
      isAdminLogin: false,
      showingFeesStatus: ['Active', 'Completed', 'Extended'],
      resendButtonDisable: false,
      resendButtonText: "Confirm",
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["registerBankTransferPost"]),

    gotoPaymenPage() {
      let routeData = this.$router.resolve({
        name: "payments",
        params: {
          id: this.paymentRequestDetail._id,
        },
      });
      window.open(routeData.href, "_blank");
    },

    checkIfAdminLogin() {
      let loggedUser = this.$store.state.AppActiveUser;
      if (loggedUser.adminId && loggedUser.adminId != "" && loggedUser.loginBy === "flip admin") {
        this.isAdminLogin = true;
      }
    },

    showFeeDiv() {
      if (!this.selectedPlan && this.paymentRequestDetail.transactions.length > 0) {
        return true;
      } else if (this.selectedPlan && this.selectedPlan.currentPlan.productType && !this.paymentRequestDetail.payNowUsedCard) {
        return true;
      }

      return false;
    },

    moneyFormat(data) {
      return `$ ${parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },

    openRegisterBankTransferPopup() {
      this.registerBankTransferPopup = true;
    },

    closeRegisterBankTransferModal() {
      this.registerBankTransferPopup = false;
      this.register = {};
      this.amPm = "";
      this.errors.clear();
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    async registerBankTransfer() {
      const valid = await this.$validator.validate();

      if (valid) {
        this.$vs.loading();
        const id = this.$route.params.id;
        this.register.paymentTime = `${this.register.paymentTime} ${this.amPm}`;
        this.registerBankTransferPost({ data: this.register, id: id }).then((res) => {
          this.$vs.loading.close();
          this.closeRegisterBankTransferModal();
          this.showMessage(
            "Success",
            "The bank transfer has been successfully registered.",
            "success",
            "icon-check-circle"
          );
        }).catch((ex) => {
          this.$vs.loading.close();
          this.closeRegisterBankTransferModal();
          this.showMessage("Error", ex.message, "danger", "icon-times");
        });
      }
    },

    openDeletePopUp() {
      this.deletePopUp = true;
    },

    openResendPopUp() {
      this.resendPopUp = true;
    },

    cancel() {
      this.cancelPaymentRequest();
      this.$router.push({ name: "payment-activity" });
    },

    hideDeletePopup() {
      this.deletePopUp = false;
    },

    hideResendPopup() {
      this.resendPopUp = false;
    },

    resend() {
      this.resendButtonDisable = true;
      this.resendButtonText = 'Processing';
      this.resendPaymentRequest();
    },

    showPlans() {
      this.payLaterPopUp = true;
    },

    conditionalClass(value = "Pending") {
      let colorClass;
      switch (value) {
        case "Pending":
          colorClass = "yellow";
          break;
        case "Info-required":
          colorClass = "yellow";
          break;
        case "Expired":
          colorClass = "red";
          break;
        case "Completed":
          colorClass = "green";
          break;
        case "Cancelled":
          colorClass = "red";
          break;
        case "Re-sent":
          colorClass = "yellow";
          break;
        case "Active":
          colorClass = "blue";
          break;
        case "Refunded":
          colorClass = "yellow";
          break;
        case "Extended":
          colorClass = "blue";
          break;
        case "Submitted":
          colorClass = "yellow";
          break;
      }
      return colorClass;
    },

    momentExpiryDate(date) {
      return moment(date).format("DD-MMM-YYYY HH:mm");
    },

    paymentRequestType(val) {
      if (val.requestOptions.includes("pay-later") && val.requestOptions.includes("pay-now")) {
        if (["Pending", "Cancelled", "Expired"].includes(val.paymentStatus)) {
          return "Pay Now / Pay Later";
        }

        if (val.metadata && val.metadata.length > 0) {
          return "Pay Later";
        } else if(!val.metadata || (val.metadata && val.metadata.length == 0)) {
          return "Pay Now";
        }

        return "Pay Later";
      } else if (val.requestOptions.includes("pay-now")) {
        return "Pay Now";
      } else if (val.requestOptions.includes("pay-later")) {
        return "Pay Later";
      }
    },
    closePayLaterPopUp() {
      this.payLaterPopUp = false;
    },
    auto_grow(elem) {
      let element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    },
  },
  created() {
    VeeValidate.Validator.extend("is_time", {
      getMessage: (field) => `The format must be HH:MM`,
      validate: (value) =>
        new Promise((resolve) => {
          let regex = new RegExp("([0-1][0-9]|2[0-3]):([0-5][0-9])");
          resolve({ valid: value && regex.test(value) });
        }),
    });
    VeeValidate.Validator.extend("requiredAmount", {
      getMessage: (field) => "Amount field is required",
      validate: (value) => !!value,
    });
    this.checkIfAdminLogin();
  },
  computed: {
    rules() {
      return "requiredAmount";
    },

    selectedPlan() {
      let plan = null;

      if (this.paymentRequestDetail.requestOptions.includes("pay-later")) {
        plan = this.paymentRequestDetail.selectedPaymentPlan ? this.paymentRequestDetail.selectedPaymentPlan : this.paymentRequestDetail.payLaterPlan[0];
      }

      return plan;
    },

    isOneOff() {
      return (this.paymentRequestDetail.requestType === "one-off");
    },

    isRecurring() {
      return (this.paymentRequestDetail.requestType === "recurring");
    },

    hasPaylater() {
      return (this.paymentRequestDetail.requestOptions.includes("pay-later"));
    },

    hasPayNow() {
      return (this.paymentRequestDetail.requestOptions.includes("pay-now"));
    }
  },
};
</script>

